<template>
    <div class="bg-mineralgreen py-10">
        <div class="container mx-auto">
            <div class="row justify-center ">
                <div class="col-12 col-sm-6 col-md-2 col-lg-2 p-8 text-white">
                    <q-img width="100%" src="@/assets/media/brands/ibc-international-bio-cosmetics-inverse-pastelgolden-icon.svg" no-spinner no-transition />
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-3 p-8 text-white">
                    <div class="col-12 flex flex-col justify-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Suivez-nous aussi sur:</h1>
                        <div class="bg-skincolor h-1 mt-3 w-32"></div>
                        <div class="col-12 flex flex-row mt-5">
                            <q-btn color="lighty" icon="mdi-facebook" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-instagram" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-twitter" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-linkedin" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7 p-8">
                    <div class="col-12 flex flex-col justify-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Inscrivez-vous à la newsletter IBC:</h1>
                        <div class="bg-skincolor h-1 mt-3 w-32"></div>
                        <div class="col-12 flex flex-row">
                            <p class="text-white py-3">Inscrivez-vous à notre newsletter pour mieux connaître notre histoire, <br> recevoir des conseils beauté d’experts et vous tenir informé de nos nouveautés !</p>
                            <input type="email" v-model="form.email" placeholder="Saissisez votre adresse email" class="form-input border-pastelgolden-dark focus:border-pastelgolden-dark rounded-md text-mineralgreen w-6/12 mr-4 focus:ring-0" />
                            <q-btn @click.prevent="newsletter" :loading="loader" :disabled="v$.form.$invalid" type="sumbit" class="bg-skincolor hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-md w-3/12 mr-4">S'inscrire</q-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-mineralgreen-dark border-t border-mineralgreen-dark"></div>

            <div class="row py-10 justify-center p-5">
                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Notre Marque</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">L'Entreprise</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Mission et Valeurs</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Mot du Directeur</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Nos Engagements</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Notre Savoir-Faire Bio</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Menu</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Ingrédients naturels</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Conseils d’Experts</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Foire aux questions</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Plan du site</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Aide en ligne</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Nos Produits</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Corps</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Visage</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Capillaire</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Hygiène</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">En savoir plus</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Réseaux sociaux</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Facebook</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Twitter</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Instagram</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Linkedin</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useValidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import Mailer from "@/app/services/mailer.service"
import { Notify } from "quasar"
import Message from "@/app/config/message"

export default {
    name: "Footer",
    data() {
        return {
            v$: useValidate(),
            form: {
                email: null,
            },
            loader: false,
        }
    },
    validations() { // validation form
        return {
            form: {
                email: { required, email },
            },
        }
    },
    methods: {
        newsletter () {
            this.loader = true
            Mailer
                .newsletter(this.form)
                .then((response) => {
                    if (response.data) {
                        this.loader = false
                        return Notify.create(response.data.message)
                    }
                    this.loader = false
                    Notify.create(Message.notsent)
                })
                .catch((error) => {
                    if (error.response.data) {
                        Notify.create(Message.failed)
                        this.loader = false
                        return Notify.create(error.response.data.message)
                    }
                    this.loader = false
                    Notify.create(Message.error)
                })
        }
    },
    created() {},
}
</script>

<style>

</style>
