<template>
    <div>
        <!-- TOP / BRAND -->
        <!-- <div class="bg-mineralgreen-dark p-1"></div> -->
        <nav class="w-full fixed top-0 left-0 right-0 z-50 bg-white">
            
            <div class="container mx-auto flex flex-col flex-wrap justify-between bg-white pb-1">
                <div class="row items-center justify-between">
                    <div class="col-4 col-sm-4 col-md-2 p-2">
                        <router-link to="/"><q-img src="@/assets/media/brands/ibc-international-bio-cosmetics-default-logotype.svg" fit="contain" no-spinner no-transition /></router-link>
                    </div>
                    <div class="col-6 col-sm-6 p-2">
                        <div class="flex flex-row items-center justify-end">
                            <span class="mx-2 text-mineralgreen">Langue : </span>
                            <span class="mx-1">
                                <a href="/"><q-img height="20px" width="20px" src="@/assets/media/flag-fr.svg" fit="contain" no-spinner no-transition /></a>
                            </span>
                            <span class="mx-1">
                                <a href="/en"><q-img height="20px" width="20px" src="@/assets/media/flag-en.svg" fit="contain" no-spinner no-transition /></a>
                            </span>
                        </div>
                    </div>
                </div>
                
                <div class="">
                    <div class="col-12">
                        <div class="bg-white">
                            <ul class="flex flex-wrap flex-col justify-center sm:flex-row my-4">
                                <li><a href="/" :class="'text-mineralgreen hover:text-mineralgreen-dark ' + active('Index')  + ' font-semibold uppercase px-5'">Bienvenue</a></li>
                                <li><a href="/page/enterprise" :class="'text-mineralgreen hover:text-skincolor ' + active('Enterprise')  + ' font-semibold uppercase px-5'">L'Entreprise</a></li>
                                <li><a href="/page/products" :class="'text-mineralgreen hover:text-skincolor ' + active('Products')  + ' font-semibold uppercase px-5'">Nos Produits</a></li>
                                <li><a href="/page/ingredients" :class="'text-mineralgreen hover:text-skincolor ' + active('Ingredients')  + ' font-semibold uppercase px-5'">Nos Ingrédients</a></li>
                                <li><a href="/page/experts-counselings" :class="'text-mineralgreen hover:text-skincolor ' + active('ExpertsCounselings')  + ' font-semibold uppercase px-5'">Conseils d'Experts</a></li>
                                <li><a href="/page/contact-us" :class="'text-mineralgreen hover:text-skincolor ' + active('Contact')  + ' font-semibold uppercase px-5'">Contactez-nous</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                
            <div class="row bg-skincolor pt-1"></div>

        </nav>

        <div style="margin-top: 135px;"></div>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    computed: {},
    methods: {
        active (name) {
            if (this.$route.name === name) {
                return " text-mineralgreen-dark "
            }
        },

        // toggle menu
        toggle () {

        }
    },
    created() {},
}
</script>

<style>

</style>
