<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Nos Conseils d'Experts</h1>
                        <p class="text-white">Entourés d’experts, dermatologues, médecins esthétiques et professionnels <br> de la peau, nous vous livrons tous les astuces et secrets de beauté...</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-masque-carotte.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">Masque antirides à la Carotte fait maison</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Ce masque à la Carotte va illuminer
                                        votre teint ! La béta-carotène (qui lui donne sa couleur
                                        orange) et la vitamine C revitalise la peau... et lui donne
                                        bonne mine instantanément !...
                                    </p>
                                    <q-btn to="/page/expert-counseling/1/masque-antirides-a-la-carotte-fait-maison" size="sm" unelevated label="En savoir plus &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-levres-seches.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">Lèvres sèches en temps d'harmattan ?</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        En période d'harmattan, les lèvres, très exposées, sont soumises à rudes épreuves. 
                                        Lorsqu’elles sont gercées, elles sont déshydratées et irritées...
                                    </p>
                                    <q-btn to="/page/expert-counseling/2/levres-seches-en-temps-d-harmattan" size="sm" unelevated label="En savoir plus &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-cernes.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">Astuces naturelles pour atténuer les cernes</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Qu’elles soient héréditaires, causées par la fatigue, le stress ou encore 
                                        un déséquilibre hormonal, les cernes ne sont jamais les bienvenues sur un visage...
                                    </p>
                                    <q-btn to="/page/expert-counseling/3/astuces-attenuer-les-cernes" size="sm" unelevated label="En savoir plus &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-huile-de-sesame.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">Les bienfaits multiples de l'huile de sesame</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Ses nutriments en font une alliée à la fois sur les fourneaux et dans la salle de bain. 
                                        Découvrez les nombreuses vertus de l’huile de sésame.
                                    </p>
                                    <q-btn to="/page/expert-counseling/4/astuces-huile-de-sesame" size="sm" unelevated label="En savoir plus &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "ExpertsCounselings",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Conseils d\'Experts', // sets document title
        })
    }
};
</script>